import { useEffect, useState }       from 'react'
import { useNavigate, useParams }    from 'react-router'
import { useApolloClient, useQuery } from '@apollo/client'

import {
  GET_DETAIL_FORMVALUES,
  GET_DETAIL_LINKS,
  GET_DETAIL_LIST_DATA
} from 'queries/detail'
import { GET_LIST_DATA }         from 'queries/list'
import LargeTable                from 'components/table/LargeTable'
import useLinks                  from 'components/view/utils/link'
import { prepareLinkFormValues } from 'components/view/utils/links'
import { toListRow }             from 'components/view/utils/table'
import Fallback                  from 'components/common/Fallback'
import { getDisplayProps }       from 'components/render/utils/render'

import MenuItemProvider        from 'contexts/MenuContext'
import { useLocale }           from 'hooks/locale'
import { useNotifier }         from 'hooks/notification'
import { useGearsTranslation } from 'hooks/translation'
import { useTranslator }       from 'hooks/translator'
import RenderContextProvider   from 'contexts/RenderContext'
import { useRenderContext }    from 'hooks/render'
import { F }                   from 'helpers/formatter'
import { handleResult }        from 'utils/utils'
import { createDataLoader }    from 'utils/table'
import { Schema, Section }     from 'types/view'

export interface ListProps {
  schema:  Schema
  section: Section
  pageSize?: number
}

interface ListWithContextMenuProps extends ListProps {
  detailLinks: any[]
}

function ListTable(listProps: ListProps) {
  console.log("Render list with schema: %o", listProps.schema)
  return (
    <Fallback name="list">
      <RenderContextProvider parent="list">
        <ListComponent {...listProps}/>
      </RenderContextProvider>
    </Fallback>
  )
}

const ListComponent = (listProps: ListProps) => {
  const notifier = useNotifier()

  if (listProps.section.detail) {
    const detailLinkResult = useQuery(GET_DETAIL_LINKS, { variables: { key: listProps.section.detail } })

    return handleResult(
      detailLinkResult,
      notifier,
      (data: any) => <ListWithContextMenu {...listProps} detailLinks={data.detail.links} />
    )
  }
  else
    return <ListWithContextMenu {...listProps} detailLinks={[]} />
}

const ListWithContextMenu = ({ schema, section, pageSize, detailLinks }: ListWithContextMenuProps) => {
  const notifier          = useNotifier()
  const client            = useApolloClient()
  const { t, translator } = useGearsTranslation()
  const context           = useRenderContext()
  const { links }         = useLinks({...context, parent: "contextmenu"}, detailLinks)

  const handleIdClick = (id: string) => (e: Event) => {
    const success = ()              => { notifier.message(t('view.menu.copy.clipboard.success', { variables: { id } })!) }
    const failure = (error: string) => { notifier.message(t('view.menu.copy.clipboard.failure', { variables: { error } })!) }

    navigator.clipboard.writeText(id).then(success, failure)
  }

  const createLinkOnClick = (link: any, index: number, row: any) => {
    const detailLink = detailLinks[index]
    const detailKey  = section.detail
    const detailId   = row.id
    const linkKey    = detailLink.key
    const linkKind   = detailLink.kind

    const variables = { detailKey, detailId, linkKey }
    const path      = "data.detail.instance.link.formValues"
    const query     = GET_DETAIL_FORMVALUES
    const request   = { query, variables }

    return (e: Event) => {
      link.onClick(e, prepareLinkFormValues(client, notifier, request, path, linkKind))
    }
  }

  const handleListRowMenu = (row: any) => {
    const detailKey = section?.detail
    
    // copy row id
    const copyId = row.id && {
      icon   : 'ContentCopy',
      onClick: handleIdClick(row.id),
      label  : t('view.menu.copy.id')
    }

    // go to detail
    const showDetail = detailKey && row.onClick && {
      icon   : 'ManageSearch',
      onClick: row.onClick,
      label  : t('view.menu.details')
    }

    // go to processes
    const rowLinks = links.map((link: object, index: number) => ({...link, onClick: createLinkOnClick(link, index, row)}))
    return [copyId, showDetail, ...rowLinks].filter(x => x)
  }

  return (
    <MenuItemProvider menu={handleListRowMenu}>
      <ListContents schema={schema} section={section} pageSize={pageSize}/>
    </MenuItemProvider>
  )
}

const ListContents = ({ schema, section, pageSize }: ListProps) => {
  const client = useApolloClient()
  const {context} = useRenderContext()
  const navigate = useNavigate()
  const {language} = useLocale()
  const {translator} = useTranslator()
  const notifier = useNotifier()
  const params = useParams()
  const [columns, setColumns] = useState<any[]>([])

  const isDetail = context === "detail"
  const detail = section.detail
  const listKey = section.key
  const detailKey = params.detail
  const parentListId = params.id
  const currentDetail = isDetail ? { id: params.id, detail: params.detail } : undefined

  const toListLabel = (columnKey: string, columnLabel: string) =>
    translator.translate(
      `${schema.translationBaseKey}.labels.${columnKey}`,
      { user: true, fallback: columnLabel, format: F.toTitleCase }
    )

  useEffect( () => {
    const columns = section.fields.map((field: any) => {
      const label = toListLabel(field.key, field.label)!
      const displayProps = getDisplayProps(field, label)
      return {
        ...field,
        headerName: label,
        field: field.key,
        cellProps: { sx: { whiteSpace: 'nowrap', verticalAlign: "top" } },
        displayProps
      }
    })
    setColumns(columns)
  },[language])

  const requestInfo = {
    description: isDetail ? `nested list '${detailKey}.${listKey}'` : `list '${listKey}'`,
    query:       isDetail ? GET_DETAIL_LIST_DATA : GET_LIST_DATA,
    variables:   isDetail ? { detailKey, listKey, parentListId } : { key: listKey },
    dataPath:    isDetail ? 'data.detail.instance.list.data'  : 'data.list.data',
    countPath:   isDetail ? 'data.detail.instance.list.count' : 'data.list.count',
  }

  //console.log("ListContents: requestInfo=%o, section=%o, columns=%o", requestInfo, section, columns)
  const toTableRow = (row: any) => toListRow({translator, notifier, row, detail, section, currentDetail, navigate})
  const dataLoader = createDataLoader(client, requestInfo, toTableRow)

  return (
    <LargeTable
      description={requestInfo.description}
      disableFilter={!section.searchable}
      pageSize={pageSize}
      columns={columns}
      dataLoader={dataLoader}
    />
  )
}

export default ListTable
