import { useEffect, useState } from "react"
import { useNavigate }         from "react-router"
import { useApolloClient }     from "@apollo/client"

import {
  addOnClick,
  combineLinks,
  createLabelLinks,
  createOnClickLinks,
  createTipLinks,
  getEnabledLinks,
  prepareLinkFormValues,
} from "components/view/utils/links"

import { useDashboardMenuItems } from "hooks/config"
import { useLocale }             from "hooks/locale"
import { useNotifier }           from "hooks/notification"
import useStartProcess           from "hooks/process"
import { useRenderContext }      from "hooks/render"
import { useTranslator }         from "hooks/translator"
import { GET_DETAIL_FORMVALUES } from "queries/detail"
import { GET_LIST_FORMVALUES }   from "queries/list"

const useLabelLinks = (context, runtimeLinks) => {
  const { translator }    = useTranslator()
  const { language }      = useLocale()
  const [links, setLinks] = useState(createLabelLinks(translator, context, runtimeLinks))

  useEffect(() => {
    setLinks(createLabelLinks(translator, context, runtimeLinks))
  },[language])

  return links
}

const useTipLinks = (runtimeLinks) => {
  const menuItems         = useDashboardMenuItems()
  const { translator }    = useTranslator()
  const [links, setLinks] = useState(createTipLinks(translator, runtimeLinks, menuItems))
  const {language}        = useLocale()

  useEffect(() => {
    setLinks(createTipLinks(translator, runtimeLinks, menuItems))
  },[language])

  return links
}

const useOnClickLinks = (runtimeLinks) => {
  const notifier                = useNotifier()
  const size                    = runtimeLinks.length
  const [loadings, setLoadings] = useState(Array(size).fill(true))
  const [links, setLinks]       = useState(createOnClickLinks(runtimeLinks, notifier))
  const navigate                = useNavigate()
  const client                  = useApolloClient()
  const startProcess            = useStartProcess()

  const setOnClickByIndex = (index) =>
    (onClick) => {
      if (!loadings[index])
        return

      setLinks(previous => {
        const links = [...previous]
        links[index].onClick = onClick
        return links
      })
      setLoadings(previous => {
        const loadings  = [...previous]
        loadings[index] = false
        return loadings
      })
    }

  useEffect(() => {
    runtimeLinks.forEach((link, index) => addOnClick({startProcess, notifier, navigate, client}, setOnClickByIndex(index), link))
  }, [])

  return {loading: loadings.some(x => x), links}
}

const useLinks = (context, runtimeLinks) => {
  const enabledLinks      = getEnabledLinks(runtimeLinks)
  const tipLinks          = useTipLinks(enabledLinks)
  const labelLinks        = useLabelLinks(context, enabledLinks)
  const { loading,
    links: onClickLinks } = useOnClickLinks(enabledLinks)
  const iconLinks         = useIconLinks(enabledLinks)
  const links             = combineLinks(tipLinks, labelLinks, onClickLinks, iconLinks)

  return {loading, links}
}

export const useIconLinks = (runtimeLinks) => {
  return runtimeLinks.map(useIconLink)
}

export const useIconLink = (runtimeLink) => {
  return {icon: runtimeLink.icon, ref: runtimeLink.ref}
}

export const useViewLinks = (runtimeLinks) => {
  const context             = useRenderContext()
  const {loading, links}    = useLinks(context, runtimeLinks)
  const enabledRuntimeLinks = getEnabledLinks(runtimeLinks)
  const client              = useApolloClient()
  const notifier            = useNotifier()
  const isDetail            = context.context == "detail"
  const isList              = context.context == "list"

  const getRuntimeLink = (index) => enabledRuntimeLinks[index]

  // augment onClick event to add form values incase we have a detail link with PROCESS_FILL kind
  const createDetailOnClick = (link, index) => {
    const detailId  = isDetail && context.props.detailId
    const detailKey = context.props.detailKey
    const l         = getRuntimeLink(index)
    const linkKey   = l.key
    const linkKind  = l.kind
    const variables = { detailKey, detailId, linkKey }
    const path      = "data.detail.instance.link.formValues"
    const query     = GET_DETAIL_FORMVALUES
    const request   = { query, variables }

    return (e) => {
      const valuesPromise = prepareLinkFormValues(client, notifier, request, path, linkKind)
      link.onClick(e, valuesPromise)
    }
  }

  const createListOnClick = (link, index) => {
    const listKey   = context.props.listKey
    const l         = getRuntimeLink(index)
    const linkKey   = l.key
    const linkKind  = l.kind

    const variables = { listKey, linkKey }
    const path      = "data.list.link.formValues"
    const query     = GET_LIST_FORMVALUES
    const request   = { query, variables }

    return (e) => {
      const valuesPromise = prepareLinkFormValues(client, notifier, request, path, linkKind)
      link.onClick(e, valuesPromise)
    }
  }

  const createLinks = () => {
    switch (true) {
      case loading:
        return links
      case isDetail:
        return links.map((link, index) =>({...link, onClick: createDetailOnClick(link, index)}))
      case isList:
        return links.map((link, index) =>({...link, onClick: createListOnClick(link, index)}))
      default:
        return links
    }
  }

  return {loading, links: createLinks()}
}

export default useLinks

