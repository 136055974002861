import { CopyRowButton, DeleteRowButton } from 'components/form/fields/multiple';
import AddRowButton from 'components/form/fields/multiple/AddRowButton';
import { useFieldInfo } from 'hooks/field';
import { MultipleRows, MultipleSelected } from 'types/multiple';
import { SetState } from 'types/react';

import { TableCell } from '@mui/material';

export type MultipleButtonProps = {
  rows: MultipleRows
  index: number
  selected: MultipleSelected
  setSelected: SetState<MultipleSelected>
}

const MultipleTableRowActions = (props: MultipleButtonProps) => {
  const { info } = useFieldInfo()
  const mode = info.field.mode

  if (mode === "open")
    return (
      <TableCell align={"right"} key={"actions"}  style={{width: "1%", whiteSpace: 'nowrap', padding: 0, paddingRight: "8px"}} >
        <AddRowButton    {...props} />
        <CopyRowButton   {...props} />
        <DeleteRowButton {...props} />
      </TableCell>
    )
  else return null
}

export default MultipleTableRowActions
