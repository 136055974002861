import _ from 'lodash'
import { TableHead, TableRow } from '@mui/material'

import {
    MultipleTableHeaderActionCell, MultipleTableHeaderFieldCells, MultipleTableHeaderSelectCell
} from 'components/form/fields/multiple'
import { MultipleRowProps } from 'components/form/fields/multiple/MultipleTable'

const MultipleTableHeader = (props: MultipleRowProps) => {
  return (
    <TableHead>
      <TableRow>
        <MultipleTableHeaderSelectCell {...props} key="selection" />
        <MultipleTableHeaderFieldCells {...props} />       
        <MultipleTableHeaderActionCell key="action" />
      </TableRow>
    </TableHead>
  )
}

export default MultipleTableHeader