import { 
  Autocomplete,
  TextField }                    from "@mui/material"
import { useTranslation }       from 'react-i18next'
import { selectOption }         from "utils/option-utils";
import { useConfig }            from "hooks/config";
import { useEffect, useState }  from "react";

const ThemeSelector = () => {
  const { t }                                 = useTranslation()
  const {props: {themeKey, setThemeKey}} = useConfig()
  const options                               = useThemeOptions()
  const [value, setValue]                     = useState(selectOption(options, themeKey))

  useEffect(() => {
    if (themeKey != value.value)
      setValue(selectOption(options, themeKey))
  },[themeKey])

  if (options.length <= 1)
    return null

  return (
    <Autocomplete
      filterOptions={(x) => x}

      isOptionEqualToValue={(option, value) => option.value === value?.value}
      options={options}
      style={{ width: 300 }}
      value={value}
      autoHighlight
      disableClearable
      onChange={(event, newOption) => {
        event.preventDefault()
        event.stopPropagation()
        setThemeKey(newOption.value);
      }}

      renderInput={(params) => (
        <TextField autoComplete='new-password' {...params} label={t('theme')} fullWidth />
      )}
    />
  )
}

const useThemeOptions = () => {
  const {props: {addDefaultTheme: addDefault, themeKey, themes}} = useConfig()
  const addDefaultTheme                       = addDefault || themeKey == 'default'
  const selectableThemes                      = addDefaultTheme ? themes : themes.filter(theme => theme.key != 'default')

  return selectableThemes.map(theme => ({value: theme.key, label: theme.label}))
}

export default ThemeSelector