import { TableCell, Checkbox } from '@mui/material'
import { useFieldInfo } from 'hooks/field'
import PropTypes from 'prop-types'
import MultipleDragButton from 'components/form/fields/multiple/MultipleDragButton'

const MultipleTableRowSelectCell = ({setSelected, selected,  index}) => {
  const { info } = useFieldInfo()
  const mode     = info.field.mode

  return mode === "open" ? (
    <>
      <TableCell key="drag" sx={{padding: 0, width: "1%"}} >
        <MultipleDragButton/>
      </TableCell>
      <TableCell key="selection" sx={{ width: "1%", padding: 0 }}>
        <MultipleRowCheckBox index={index} selected={selected} setSelected={setSelected} />
      </TableCell>
    </>
  ) : null
}

export const MultipleRowCheckBox = ({selected, setSelected, index}) => {
  const handleChange = (e, value) => {
    if (value)
      setSelected([...selected, index])
    else
      setSelected(selected.filter(rowId => rowId !== index))
  }

  return <Checkbox
    tabIndex={-1}
    size="small"
    color="primary"
    checked={selected.includes(index)}
    onChange={handleChange}
    name="multiple"
  />
}

MultipleTableRowSelectCell.propTypes = {
  index       : PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected   : PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}

export default MultipleTableRowSelectCell
