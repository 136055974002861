import { MultipleHeaderTableCellContents } from 'components/form/fields/multiple';
import { MultipleRowProps } from 'components/form/fields/multiple/MultipleTable';
import {
    getMultipleColumns, MultipleColumnProps
} from 'components/form/fields/utils/multiple-utils';
import { toFieldRenderType } from 'components/form/utils/field-utils';
import { useConfig } from 'hooks/config';
import { useFieldInfo } from 'hooks/field';
import { useFormInfo } from 'hooks/form';
import { useTranslator } from 'hooks/translator';
import React from 'react';
import { MultipleColumn, MultipleRows, MultipleSelected } from 'types/multiple';
import { SetState } from 'types/react';

import { TableCell } from '@mui/material';

const MultipleTableHeaderFieldCells = ({rows, selected, setSelected}: MultipleRowProps) => {
  const formInfo                 = useFormInfo()
  const {translator}             = useTranslator()
  const {info}                   = useFieldInfo()
  const {props: {taskRendering}} = useConfig()

  const props: MultipleColumnProps = {
    taskRendering,
    formInfo,
    translator,
    rpath: info.rpath,
    processKey: formInfo.processDefinition.key, 
    rows,
    ...info.field
  }

  const columns = getMultipleColumns( props )
  return (
    <React.Fragment>
      { columns
        .map((column, index) =>
          <MultipleTableHeaderCell
            key={index}
            column={column}
            index={index}
            selected={selected}
            setSelected={setSelected}
            rows={rows}
          />
        )
      }
    </React.Fragment>
  )
}

type MultipleHeaderCellProps = {
  rows: MultipleRows, 
  selected: MultipleSelected, 
  setSelected: SetState<MultipleSelected>
  index: number
  column: MultipleColumn
}

export const useInputTableCellStyle = (index: number) => {
  const formInfo                  = useFormInfo()
  const {props: {taskRendering}}  = useConfig()
  const {info: {field: {fields}}} = useFieldInfo()
  const field                     = fields![index]
  const type                      = toFieldRenderType(formInfo, field, taskRendering)
  
  return type == "SEGMENTED BOOLEAN BUTTON" 
    ? {
      display: "inline-block",
        width: "85px",
        //maxWidth: "85px"
      }
    : {
      }


}

const MultipleTableHeaderCell = ({column, index, selected, rows, setSelected}:MultipleHeaderCellProps ) => {
  const tableCellStyle  = (index: number, selected: MultipleSelected) => index === 0 && selected.length ? {paddingLeft: "0px"} : {}
  //const style = useInputTableCellStyle(index)

  return (
    <TableCell key={index} style={{ whiteSpace: 'nowrap' }} sx={{ ...tableCellStyle(index, selected)}}>
      <MultipleHeaderTableCellContents
        selected={selected}
        setSelected={setSelected}
        id={index}
        rows={rows}
        column={column}
      />
    </TableCell>
  )

}
export default MultipleTableHeaderFieldCells
