import { Typography, Box }            from '@mui/material';
import { MultipleTableHeaderActions } from 'components/form/fields/multiple';
import { useFieldInfo }               from 'hooks/field';

const MultipleHeaderTableCellContents = ({id, selected, setSelected, rows, column}) => {
  const { info }       = useFieldInfo()
  const mode           = info.field.mode

  if (mode === "open" && id === 0 && selected.length ) {
    return (
      <Box sx={{display: "flex", flexFlow: 'row'}} >
        <MultipleTableHeaderActions
          selected={selected}
          setSelected={setSelected}
          id={id}
          rows={rows}
        />

        <Box sx={{flexGrow: 1, display: "flex", alignItems: "center"}}>
          <Typography variant="body2" >
            {column.name}{column.required ? "*" : ""}
          </Typography>
        </Box>
      </Box>
    )
  } else {
    return (
      <Typography variant="body2" >
        {column.name}{column.required ? "*" : ""}
      </Typography>
    )
  }
}

export default MultipleHeaderTableCellContents
